<div class="grid grid-cols-2 gap-6" *ngIf="!(loadCarBaseData | async);else placeholder">
  <div class="col-span-2 flex justify-end gap-6">
    <app-blue-btn (clickEmitter)="duplicateCar()" btnText="Duplicate car"></app-blue-btn>
    <primary-btn (clickEmitter)="saveCar()" btnText="Save car"></primary-btn>
  </div>

  <div class="col-span-1 flex flex-col gap-6">
    <app-main-content-layout>
      <div class="title">
        <p>Technical data</p>
      </div>

      <div class="flex flex-col gap-6" *ngIf="!resetBaseInfo;else editBaseData">
        <app-main-content-row-layout>
          <p>Make</p>

          <app-read-only-input class="secondContent">{{car.make}}</app-read-only-input>
        </app-main-content-row-layout>

        <app-main-content-row-layout>
          <p>Model</p>

          <app-read-only-input class="secondContent">{{car.model}}</app-read-only-input>
        </app-main-content-row-layout>

        <app-main-content-row-layout>
          <p>Fuel type</p>

          <app-read-only-input class="secondContent">{{car.fuelType}}</app-read-only-input>
        </app-main-content-row-layout>

        <app-main-content-row-layout>
          <p>Gearbox</p>

          <app-read-only-input class="secondContent">{{car.gearbox}}</app-read-only-input>
        </app-main-content-row-layout>

        <app-main-content-row-layout>
          <p>Variant</p>

          <app-read-only-input class="secondContent">{{car.variant}}</app-read-only-input>
        </app-main-content-row-layout>

        <app-main-content-row-layout *ngIf="car.trim">
          <p>Trim</p>

          <app-read-only-input class="secondContent">{{car.trim}}</app-read-only-input>
        </app-main-content-row-layout>

        <app-main-content-row-layout>
          <div class="secondContent flex justify-end">
            <secondary-btn (clickEmitter)="openWarningModalModal(resetCarModalTemplate!, 'reset-car')">
              Reset info
            </secondary-btn>
          </div>
        </app-main-content-row-layout>
      </div>

      <ng-template #editBaseData>
        <div class="flex flex-col gap-6">
          <app-main-content-row-layout>
            <p>
              Make
            </p>

            <app-read-only-input class="secondContent">{{car.make}}</app-read-only-input>
          </app-main-content-row-layout>

          <app-main-content-row-layout>
            <p
              [ngClass]="{'text-red font-bold' : baseCarDataForm.touched && baseCarDataForm.controls.model.touched && baseCarDataForm.controls.model.invalid}">
              Model*</p>
            <div class="secondContent">
              <app-dropdown placeholder="Select model" id="model" [disabled]="baseCarDataForm.controls.make.invalid"
                [control]="baseCarDataForm.controls.model" (emitOnChangeSelect)="loadFueltypes($event)"
                [options]="carModels">
              </app-dropdown>
            </div>
          </app-main-content-row-layout>

          <app-main-content-row-layout>
            <p
              [ngClass]="{'text-red font-bold' : baseCarDataForm.touched && baseCarDataForm.controls.model.touched && baseCarDataForm.controls.model.invalid}">
              Fuel type*</p>
            <div class="secondContent">
              <app-dropdown placeholder="Select fueltype" id="fueltype"
                [disabled]="baseCarDataForm.controls.model.invalid" [control]="baseCarDataForm.controls.fuelType"
                (emitOnChangeSelect)="loadGearbox($event)" [options]="carFuelTypes">
              </app-dropdown>
            </div>
          </app-main-content-row-layout>

          <app-main-content-row-layout>
            <p
              [ngClass]="{'text-red font-bold' : baseCarDataForm.touched && baseCarDataForm.controls.model.touched && baseCarDataForm.controls.model.invalid}">
              Gearbox*</p>
            <div class="secondContent">
              <app-dropdown placeholder="Select gearbox" id="gearbox"
                [disabled]="baseCarDataForm.controls.fuelType.invalid" [control]="baseCarDataForm.controls.gearbox"
                (emitOnChangeSelect)="loadVariants($event)" [options]="carGearboxes">
              </app-dropdown>
            </div>
          </app-main-content-row-layout>

          <app-main-content-row-layout>
            <p
              [ngClass]="{'text-red font-bold' : baseCarDataForm.touched && baseCarDataForm.controls.variant.touched && baseCarDataForm.controls.variant.invalid}">
              Variant*</p>
            <div class="secondContent">
              <app-dropdown placeholder="Select variant" id="variant"
                [disabled]="baseCarDataForm.controls.gearbox.invalid" [control]="baseCarDataForm.controls.variant"
                (emitOnChangeSelect)="loadTrims($event)" [options]="carVariants"></app-dropdown>
            </div>
          </app-main-content-row-layout>

          <app-main-content-row-layout>
            <p
              [ngClass]="{'text-red font-bold' : baseCarDataForm.touched && baseCarDataForm.controls.trim.touched && baseCarDataForm.controls.trim.invalid}">
              Trim</p>
            <div class="secondContent">
              <app-dropdown placeholder="Select trim" id="trim" [disabled]="baseCarDataForm.controls.variant.invalid"
                [control]="baseCarDataForm.controls.trim" [options]="carTrims"></app-dropdown>
            </div>
          </app-main-content-row-layout>

          <div class="flex justify-end gap-6 items-center">
            <secondary-btn id="reset" (clickEmitter)="resetCar()" btnText="Reset"></secondary-btn>

            <primary-btn id="fueltype" btnText="See identified cars" [disableBtn]="carAlternatives.length === 0"
              (clickEmitter)="openCarsModal()"></primary-btn>

            <primary-btn id="identify" btnText="Identify car" [blueBtn]="true" [disableBtn]="baseCarDataForm.invalid"
              (clickEmitter)="identifyCar()"></primary-btn>
          </div>
        </div>
      </ng-template>

      <app-main-content-row-layout>
        <p>Engine capacity</p>
        <app-input placeholder="Insert engine capacity" id="engine-capacity" [isNumberInput]="true"
          [control]="technicalDataForm.controls.engineVolume" class="secondContent"></app-input>
      </app-main-content-row-layout>

      <app-main-content-row-layout>
        <p
          [ngClass]="{'text-red font-bold' : technicalDataForm.touched && technicalDataForm.controls.enginePower.touched && technicalDataForm.controls.enginePower.invalid}">
          Engine power*</p>
        <app-input placeholder="Insert engine power" id="engine-power" [isNumberInput]="true"
          [control]="technicalDataForm.controls.enginePower" class="secondContent"></app-input>
      </app-main-content-row-layout>

      <app-main-content-row-layout>
        <p
          [ngClass]="{'text-red font-bold' : technicalDataForm.touched && technicalDataForm.controls.bodyType.touched && technicalDataForm.controls.bodyType.invalid}">
          Body type*</p>
        <app-dropdown placeholder="Select body type" id="bodytype" [control]="technicalDataForm.controls.bodyType"
          [options]="carBodyTypes" class="secondContent">
        </app-dropdown>
      </app-main-content-row-layout>

      <app-main-content-row-layout>
        <p>Driving wheels</p>
        <app-dropdown placeholder="Select driving wheels" id="driving-wheels"
          [control]="technicalDataForm.controls.drivingWheels" [options]="carDrivingWheels" class="secondContent">
        </app-dropdown>
      </app-main-content-row-layout>

      <app-main-content-row-layout>
        <p>CO2 NEDC</p>
        <app-input placeholder="Insert CO2 NEDC" id="co2-nedc" [isNumberInput]="true"
          [control]="technicalDataForm.controls.co2NEDC" class="secondContent"></app-input>
      </app-main-content-row-layout>

      <app-main-content-row-layout>
        <p>CO2 WLTP</p>
        <app-input placeholder="Insert CO2 WLTP" id="co2-wltp" [isNumberInput]="true"
          [control]="technicalDataForm.controls.co2WLTP" class="secondContent"></app-input>
      </app-main-content-row-layout>

    </app-main-content-layout>

    <!-- registration form -->
    <app-main-content-layout>
      <div class="title">
        <p>Registration & car details</p>
      </div>

      <app-main-content-row-layout>
        <p>First registration</p>
        <app-datepicker id="first-reg" class="secondContent"
          [control]="registrationForm.controls.firstReg"></app-datepicker>
      </app-main-content-row-layout>

      <app-main-content-row-layout>
        <p
          [ngClass]="{'text-red font-bold' : registrationForm.controls.manufactureYear.touched && registrationForm.controls.manufactureYear.invalid}">
          Manufacture Year*</p>
        <app-dropdown placeholder="Select manufacture year" id="man-year" class="secondContent"
          [control]="registrationForm.controls.manufactureYear" [options]="carManufactureYears"></app-dropdown>
      </app-main-content-row-layout>

      <app-main-content-row-layout>
        <p
          [ngClass]="{'text-red font-bold' : registrationForm.touched && registrationForm.controls.mileage.touched && registrationForm.controls.mileage.invalid}">
          Mileage*</p>

        <app-input-number placeholder="Insert mileage" id="mileage" class="secondContent"
          [control]="registrationForm.controls.mileage"></app-input-number>
      </app-main-content-row-layout>

      <app-main-content-row-layout>
        <p>Color</p>
        <app-dropdown placeholder="Select color" id="color" class="secondContent" [options]="carColors"
          [control]="registrationForm.controls.color">
        </app-dropdown>
      </app-main-content-row-layout>

      <app-main-content-row-layout>
        <p>Seats</p>
        <app-input placeholder="Insert seats" id="seats" class="secondContent" [isNumberInput]="true"
          [control]="registrationForm.controls.seats">
        </app-input>
      </app-main-content-row-layout>

      <app-main-content-row-layout>
        <p>Doors</p>
        <app-input placeholder="Insert doors" id="doors" class="secondContent" [isNumberInput]="true"
          [control]="registrationForm.controls.doors">
        </app-input>
      </app-main-content-row-layout>
    </app-main-content-layout>

    <!-- identification form -->
    <app-main-content-layout>
      <div class="title">
        <p>Identification</p>
      </div>

      <app-main-content-row-layout>
        <p>COC from seller</p>

        <app-toggle class="secondContent flex justify-end"
          [toggleFormControl]="identificationForm.controls.cocFromSeller"
          [toggleLabel]="identificationForm.controls.cocFromSeller.value ? 'Yes' : 'No'"></app-toggle>
      </app-main-content-row-layout>



      <app-main-content-row-layout *ngIf="!resetBaseInfo;else editLocation">
        <p>Location</p>

        <app-read-only-input class="secondContent">{{locationName}}</app-read-only-input>
      </app-main-content-row-layout>

      <ng-template #editLocation>
        <app-main-content-row-layout>
          <p
            [ngClass]="{'text-red font-bold' : identificationForm.touched && identificationForm.controls.location.touched && identificationForm.controls.location.invalid}">
            Location*</p>
          <app-dropdown placeholder="Select location" id="location" class="secondContent"
            [control]="identificationForm.controls.location" [options]="countries"></app-dropdown>
        </app-main-content-row-layout>
      </ng-template>


      <app-main-content-row-layout>
        <p>VIN</p>
        <p class="text-red" *ngIf="identificationForm.controls.vin.hasError('invalidLength')">
          VIN must have 17 characters
        </p>
        <p class="text-red" *ngIf="identificationForm.controls.vin.hasError('invalidChars')">
          VIN must not contain letters 'O','Q','I'
        </p>

        <app-input placeholder="Insert VIN" id="vin" class="secondContent"
          [control]="identificationForm.controls.vin"></app-input>
      </app-main-content-row-layout>

      <app-main-content-row-layout>
        <p>Registration number</p>
        <app-input placeholder="Insert registration number" id="regNo" class="secondContent"
          [control]="identificationForm.controls.regNo"></app-input>
      </app-main-content-row-layout>

      <app-main-content-row-layout>
        <p>Ad link</p>
        <app-input placeholder="Insert ad link" id="adlink" class="secondContent"
          [control]="identificationForm.controls.adLink"></app-input>
      </app-main-content-row-layout>

      <app-main-content-row-layout>
        <p>SubHeader</p>
        <app-input placeholder="Insert subHeader" id="subHeader" class="secondContent"
          [control]="identificationForm.controls.subHeader"></app-input>

        <p *ngIf="identificationForm.controls.subHeader.touched && identificationForm.controls.subHeader.invalid"
          class="absolute text-red text-sm">SubHeader must have maximum 100 characters</p>
      </app-main-content-row-layout>

      <textarea id="notes-textarea" data-cy="notes-textarea" [formControl]="identificationForm.controls.otherInfo"
        rows="5" placeholder="Insert notes here"
        class="w-full rounded border border-white-500 p-6 text-blue-100 text-sm"></textarea>
    </app-main-content-layout>

  </div>

  <div class="col-span-1 flex flex-col gap-6">
    <!-- prices form -->
    <app-main-content-layout>
      <div class=" title">
        <p>Journeys & prices settings</p>
      </div>

      <app-main-content-row-layout>
        <p
          [ngClass]="{'text-red font-bold' : pricesForm.touched && pricesForm.controls.buyingPrice.touched && pricesForm.controls.buyingPrice.invalid}">
          Buying price*</p>

        <app-input-number placeholder="Insert buying price" id="buying-price" class="secondContent"
          [control]="pricesForm.controls.buyingPrice"></app-input-number>
      </app-main-content-row-layout>

      <app-main-content-row-layout>
        <p
          [ngClass]="{'text-red font-bold' : pricesForm.touched && pricesForm.controls.minSellingPrice.touched && (pricesForm.controls.minSellingPrice.invalid || minSellingPriceValidation())}">
          Min Selling price* <fa-icon matTooltip="Min selling price must be higher than buying price"
            matTooltipPosition="above" [icon]="['fal','circle-info']"></fa-icon>
        </p>

        <app-input-number placeholder="Insert min selling price" id="min-selling-price" class="secondContent"
          [control]="pricesForm.controls.minSellingPrice"></app-input-number>
      </app-main-content-row-layout>

      <app-main-content-row-layout>
        <p>Damages</p>

        <app-input-number placeholder="Insert damages" id="damages" class="secondContent"
          [control]="pricesForm.controls.damages"></app-input-number>
      </app-main-content-row-layout>

      <app-main-content-row-layout>
        <p
          [ngClass]="{'text-red font-bold' : pricesForm.controls.vatStatus.invalid && pricesForm.controls.vatStatus.touched}">
          VAT type*</p>

        <mat-radio-group class="vat-radio secondContent flex gap-6 text-sm sm:justify-evenly"
          aria-label="Select an option" [formControl]="pricesForm.controls.vatStatus">
          <mat-radio-button [value]="true">{{vatTypes.ExVAT}}</mat-radio-button>
          <mat-radio-button [value]="false">{{vatTypes.InclVAT}}</mat-radio-button>
        </mat-radio-group>
      </app-main-content-row-layout>

      <app-main-content-row-layout>
        <p>B2B recommended price</p>

        <div class="secondContent grid grid-cols-2 gap-6 items-center">
          <app-read-only-input class="secondContent">
            {{b2bRecommendedPrice | number}}€
          </app-read-only-input>

          <secondary-btn id="get-bc" (clickEmitter)="getBC()">Get business case</secondary-btn>
        </div>
      </app-main-content-row-layout>


      <app-main-content-row-layout>
        <p>Autocomplete prices</p>

        <div class="secondContent grid grid-cols-2 gap-6 items-center">
          <app-input-number-ngmodel placeholder="Insert price"
            [(inputValue)]="autocompletePrice"></app-input-number-ngmodel>

          <secondary-btn (clickEmitter)="autocompletePrices()"> Autocomplete prices </secondary-btn>
        </div>
      </app-main-content-row-layout>


      <!-- <app-main-content-row-layout>
        <p>Taxes</p>
        <div class="secondContent flex flex-wrap gap-5">
          <div class="bg-white-300 p-3 w-max rounded" *ngFor="let tax of carTaxes">
            <p>{{tax.country}}: ${{tax.value | number}}</p>
          </div>
        </div>
      </app-main-content-row-layout> -->

      <app-main-content-row-layout class="pt-4 border-t border-white-500">
        <p class="font-bold">Journey details</p>

        <div class="secondContent flex justify-end" *ngIf="car.carStatus != carStatus.Reserved">
          <p class="w-fit px-4 py-2 text-red hover:font-bold cursor-pointer"
            (click)="openWarningModalModal(resetJourneysModalTemplate!, 'reset-journeys')">
            Reset journeys
          </p>
        </div>
      </app-main-content-row-layout>

      <!-- journeys -->
      <div *ngFor="let control of journeysGroupForm.controls; let i = index"
        class="flex flex-col gap-4 pt-4 border-t border-white-500">
        <app-main-content-row-layout>
          <p class="font-bold" [ngClass]="{'text-red': control.value.group.invalid && control.value.group.touched}">
            Journey {{i+1}}</p>

          <app-dropdown [id]="'journey-group-' + (i + 1)" placeholder="Select journey group" class="secondContent"
            [control]="control.value.group" [options]="journeyGroups">
          </app-dropdown>
        </app-main-content-row-layout>

        <app-main-content-row-layout>
          <p [ngClass]="{'text-red font-bold' : control.value.price.touched && sellingPriceValidation(control)}">
            Journey selling price* <fa-icon matTooltip="Journey selling price must be higher than min selling price"
              matTooltipPosition="above" [icon]="['fal','circle-info']"></fa-icon>
          </p>

          <app-input-number [id]="'journey-price-'+ (i + 1)" class="secondContent" [control]="control.value.price"
            placeholder="Insert selling price"></app-input-number>
        </app-main-content-row-layout>

        <app-main-content-row-layout>
          <p [ngClass]="{'text-red font-bold': control.value.from.invalid && control.value.from.touched}">Start date</p>

          <app-datepicker [id]="'journey-from-'+ (i + 1)" class="secondContent" [control]="control.value.from"
            [min]="control.value.unavailabilityForm.controls.startDate.value" [max]="control.value.until.value"
            placeholder="Select date"></app-datepicker>
        </app-main-content-row-layout>

        <app-main-content-row-layout>
          <p [ngClass]="{'text-red font-bold': control.value.startTime.invalid && control.value.startTime.touched}">
            Start time (local time)</p>

          <app-dropdown [id]="'journey-from-time-'+ (i + 1)" class="secondContent" [options]="hours"
            [control]="control.value.startTime" placeholder="Select hour"></app-dropdown>
        </app-main-content-row-layout>

        <app-main-content-row-layout>
          <p [ngClass]="{'text-red font-bold': control.value.until.invalid && control.value.until.touched}">End date</p>

          <app-datepicker [id]="'journey-to-'+ (i + 1)" class="secondContent" [control]="control.value.until"
            [min]="getMinJourneyEndDate(control)" (dateChangeEvent)="setDates($event)"
            placeholder="Select date"></app-datepicker>
        </app-main-content-row-layout>

        <app-main-content-row-layout>
          <p [ngClass]="{'text-red font-bold': control.value.endTime.invalid && control.value.endTime.touched}">
            End time (local time)
          </p>

          <app-dropdown [id]="'journey-to-time-'+ (i + 1)" class="secondContent" [options]="hours"
            [control]="control.value.endTime" placeholder="Select hour"
            (emitOnChangeSelect)="setHours($event)"></app-dropdown>
        </app-main-content-row-layout>

        <div class="flex items-center justify-end" *ngIf="!control.value.pastJourney">
          <div class="flex gap-4 items-center">
            <app-blue-btn *ngIf="control.value.journeyId.value === 'newJourney'" (clickEmitter)="saveJourney()">Save
              journey</app-blue-btn>

            <secondary-btn *ngIf="control.value.journeyId.value != 'newJourney' && car.carStatus != carStatus.Reserved"
              [id]="'update-journey-' + i" (clickEmitter)="checkJourneyUpdate(control)">
              Update journey</secondary-btn>
          </div>
        </div>
      </div>

      <app-main-content-row-layout class="pt-4 border-t border-white-500" *ngIf="car.carStatus != carStatus.Reserved">
        <div class="secondContent flex justify-end gap-4">
          <secondary-btn id="add-journey" btnText="Add Journey" (clickEmitter)="addJourney()"></secondary-btn>
        </div>
      </app-main-content-row-layout>

      <app-main-content-row-layout>
        <p>Inactive from</p>

        <app-read-only-input class="secondContent">
          <p>{{inactiveFrom ? (inactiveFrom | date:"dd/MM/yyyy HH:mm") : "dd/MM/yyyy hh:mm"}}</p>
        </app-read-only-input>
      </app-main-content-row-layout>

      <app-main-content-row-layout>
        <p
          [ngClass]="{'text-red font-bold' : identificationForm.touched && identificationForm.controls.availableFrom.touched && identificationForm.controls.availableFrom.invalid}">
          Available from*</p>

        <app-datepicker class="secondContent" [control]="identificationForm.controls.availableFrom"></app-datepicker>
      </app-main-content-row-layout>


      <app-main-content-row-layout>
        <div class="col-span-1 flex items-center">
          <p>Source</p>
        </div>

        <app-read-only-input class="secondContent">Manual</app-read-only-input>
      </app-main-content-row-layout>

      <app-main-content-row-layout>
        <div class="col-span-1 flex items-center">
          <p>Status</p>
        </div>

        <app-read-only-input class="secondContent">{{car.carStatus}}</app-read-only-input>
      </app-main-content-row-layout>
    </app-main-content-layout>

    <!-- seller -->
    <app-main-content-layout class="col-span-1" *ngIf="selectedSeller; else placeholder">
      <div class="title">
        <p>Seller details</p>
      </div>

      <app-main-content-row-layout>
        <div [ngClass]="{'text-red font-bold' : sellerControl.touched && sellerControl.invalid}">
          <p>Seller*</p>
        </div>
        <div class="secondContent">
          <input id="search-seller-input" data-cy="search-seller-input" class="search-seller" type="text" matInput
            [matAutocomplete]="auto" [formControl]="sellerControl" placeholder="Search seller">
          <div>
            <mat-autocomplete #auto="matAutocomplete">
              <div *ngIf="existsResults | async">
                <mat-option class="autocomplete-option" *ngFor="let seller of (resultItems| async)!"
                  (click)="selectSeller(seller)">
                  <div class="option-item">
                    {{seller.sellerCompanyDetails.name}}
                  </div>
                </mat-option>
              </div>
            </mat-autocomplete>
          </div>
        </div>
      </app-main-content-row-layout>

      <app-main-content-row-layout>
        <p>Country</p>

        <app-read-only-input class="secondContent">
          {{selectedSeller ?
          selectedSeller.sellerCompanyDetails.countryDetails.country :
          "No seller"}}
        </app-read-only-input>
      </app-main-content-row-layout>

      <app-main-content-row-layout>
        <p>Address</p>

        <app-read-only-input class="secondContent">
          {{selectedSeller? selectedSeller.sellerCompanyDetails.address : "No seller"}}</app-read-only-input>
      </app-main-content-row-layout>

      <app-main-content-row-layout>
        <p>VAT</p>

        <app-read-only-input class="secondContent">
          {{selectedSeller? selectedSeller.sellerCompanyDetails.vatDetails.vat : "No seller"}}</app-read-only-input>
      </app-main-content-row-layout>

      <app-main-content-row-layout>
        <p>Phone</p>

        <app-read-only-input class="secondContent">
          {{selectedSeller? selectedSeller.sellerCompanyDetails.phone : "No seller"}}</app-read-only-input>
      </app-main-content-row-layout>

      <app-main-content-row-layout>
        <p>Email</p>

        <app-read-only-input class="secondContent">
          {{selectedSeller? selectedSeller.sellerCompanyDetails.email : "No seller"}}</app-read-only-input>
      </app-main-content-row-layout>

      <app-main-content-row-layout>
        <p>Postal code</p>

        <app-read-only-input class="secondContent">
          {{selectedSeller? selectedSeller.sellerCompanyDetails.postalCode : "No seller"}}</app-read-only-input>
      </app-main-content-row-layout>
    </app-main-content-layout>
  </div>
</div>

<ng-template #placeholder>
  <app-main-content-placeholder></app-main-content-placeholder>
</ng-template>

<ng-template #resetJourneysModal>
  <div>
    <p class="font-bold">Reset journeys</p>

    <p class="font-bold pt-9 mt-3 border-t border-white-500 pb-7">Are you sure you want to reset journeys?</p>

    <p>
      By resetting journeys, the following events will be triggered
      automatically:
    </p>
    <br>
    <ul class="flex flex-col list-inside list-disc">
      <li>All offers from all Customer users will be deleted;</li>
      <li>All cars from Shopping Carts will be deleted. This will not affect the cars already purchased or on pending
        order;</li>
      <li>The car will be inactivated and will not be displayed on Shop;</li>
    </ul>

    <div class="flex justify-end gap-6 pt-6">
      <app-white-btn (clickEmitter)="cancelModal()">Cancel</app-white-btn>

      <app-white-btn bgColor="#F2264B" textColor="#FFF" (clickEmitter)="confirmModal()">Yes, reset</app-white-btn>
    </div>
  </div>
</ng-template>


<ng-template #changeJourneyGroupModal>
  <div>
    <p class="font-bold">Update journey</p>

    <p class="font-bold pt-9 mt-3 border-t border-white-500 pb-7">Are you sure you want to update this journey?</p>

    <p>
      By updating journeys with changed car group, the following events will be triggered automatically::
    </p>
    <br>
    <ul class="flex flex-col list-inside list-disc">
      <li>All users not included in the new car group will not see the car;</li>
      <li>All users not included in the new car group will lose access to this car (including Shopping carts). This will
        not affect the cars already purchased or on pending order.</li>
      <li>Cars from all users not included in the new car group car will be inactivated and will not be displayed on
        Shop;</li>
    </ul>

    <div class="flex justify-end gap-6 pt-6">
      <app-white-btn (clickEmitter)="cancelModal()">Cancel</app-white-btn>

      <primary-btn [blueBtn]="true" (clickEmitter)="confirmModal()">Yes, update</primary-btn>
    </div>
  </div>
</ng-template>

<ng-template #resetCarModal>
  <div>
    <p class="font-bold">Reset car</p>

    <p class="font-bold pt-9 mt-3 border-t border-white-500 pb-7">Are you sure you want to reset this car?</p>

    <p>
      By reseting this car, the following events will be triggered automatically:
    </p>
    <br>
    <ul class="flex flex-col list-inside list-disc">
      <li>Equipment will be deleted;</li>
      <li>Technical data will be erased;</li>
      <li>Car pictures will be erased;</li>
      <li>Car will be removed from any current offers, wish lists or buy lists;</li>
    </ul>

    <div class="flex justify-end gap-6 pt-6">
      <app-white-btn (clickEmitter)="cancelModal()">Cancel</app-white-btn>

      <app-white-btn bgColor="#F2264B" textColor="#FFF" (clickEmitter)="confirmModal()">Yes, reset</app-white-btn>
    </div>
  </div>
</ng-template>
