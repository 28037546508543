<div class="twoCols">
  <app-main-content-layout>
    <div class="title">
      <p>New offers</p>
    </div>

    <app-main-content-row-layout>
      <p>Expire date*</p>

      <app-datepicker id="offer-date" class="secondContent" [control]="orderSettingsControls.controls.expiresOn"
        [min]="tomorrow"></app-datepicker>
    </app-main-content-row-layout>

    <app-main-content-row-layout>
      <p>Buyer company*</p>

      <div class="secondContent">
        <input id="search-buyer-input" data-cy="search-buyer-input" class="search-buyer" type="text" matInput
          [matAutocomplete]="auto" [formControl]="buyerControl" placeholder="Search buyer company">

        <mat-autocomplete #auto="matAutocomplete">
          <div>
            <mat-option class="autocomplete-option" *ngFor="let buyer of buyersFiltered"
              (click)="selectBuyer(buyer.value)">
              <div class="option-item">
                {{buyer.viewValue}}
              </div>
            </mat-option>
          </div>
        </mat-autocomplete>
      </div>

    </app-main-content-row-layout>

    <app-main-content-row-layout>
      <p>User buyer*</p>

      <app-dropdown id="user-buyer" class="secondContent" [control]="orderSettingsControls.controls.client"
        [options]="usersDropdown" (emitOnChangeSelect)="selectUser($event)"
        placeholder="Select user buyer"></app-dropdown>
    </app-main-content-row-layout>
  </app-main-content-layout>

  <app-main-content-layout>
    <app-expansion-panel title="Selected buyer company" [expandedPanel]="true" panelId="buyerCompanyPanel">
      <div class="py-7 flex flex-col gap-6 border-t border-white-300 ">
        <div class="flex justify-between gap-4">
          <p>KAM Buyer</p>

          <p [title]="selectedBuyer?.buyerCompanyDetails?.name">{{selectedBuyer?.buyerCompanyDetails?.name |
            ellipsis:20}}
          </p>
        </div>


        <div class="flex justify-between gap-4">
          <p>Main company name</p>

          <p [title]="selectedBuyer?.buyerCompanyDetails?.name">{{selectedBuyer?.buyerCompanyDetails?.name |
            ellipsis:20}}</p>
        </div>

        <div class="flex justify-between">
          <p>VAT number</p>

          <p>{{selectedBuyer?.buyerCompanyDetails?.vatDetails?.vat}}</p>
        </div>

        <div class="flex justify-between">
          <p>Address</p>

          <div *ngIf="selectedBuyer">
            <p *ngIf="selectedBuyer.buyerCompanyDetails.address!.length > 0;else fullAddress">
              {{selectedBuyer!.buyerCompanyDetails.address | slice:0:20}}...
            </p>

            <ng-template #fullAddress>
              <p>{{selectedBuyer!.buyerCompanyDetails.address}}</p>
            </ng-template>
          </div>
        </div>

        <div class="flex justify-between">
          <p>City</p>

          <p>{{selectedBuyer?.buyerCompanyDetails?.city}}</p>
        </div>

        <div class="flex justify-between">
          <p>Postal code</p>

          <p>{{selectedBuyer?.buyerCompanyDetails?.postalCode}}</p>
        </div>

        <div class="flex justify-between">
          <p>Country</p>

          <p>{{selectedBuyer?.buyerCompanyDetails?.countryDetails?.country}}</p>
        </div>

        <div class="flex justify-between">
          <p>User name</p>

          <p>{{selectedUser?.firstName}} {{selectedUser?.lastName}}</p>
        </div>


        <div class="flex justify-between">
          <p>User email</p>

          <p>{{selectedUser?.email | ellipsis:20}}</p>
        </div>


        <div class="flex justify-between">
          <p>User phone</p>

          <p>{{selectedUser?.phoneNumber}}</p>
        </div>
      </div>
    </app-expansion-panel>
  </app-main-content-layout>
</div>

<ng-template #placeholder>
  <div class="twoCols">
    <app-main-content-placeholder></app-main-content-placeholder>
    <app-main-content-placeholder></app-main-content-placeholder>
  </div>
</ng-template>
