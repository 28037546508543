import { Component, OnInit, ViewChild } from '@angular/core';
import { MatCheckbox, MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { CarOnlineListUploadResponse } from 'src/app/core/models/cars-inventory.model';
import { CarFlowSyncService } from 'src/app/core/services/car-flow-sync.service';
import { SnackbarService } from 'src/app/core/services/snackbar.service';
import { TableHeaderMap } from 'src/app/shared/app-table/app-table.component';
import { ModifyPartiallyIdentifiedCarModalComponent } from '../modify-partially-identified-car-modal/modify-partially-identified-car-modal.component';
import { ModifyUnidentifiedCarsModalComponent } from '../modify-unidentified-cars-modal/modify-unidentified-cars-modal.component';
import { CarIdentifiedModalComponentCar } from 'src/app/features/car-view/car-details/car-identified-modal/car-identified-modal.component';
import { SpinnerHandlerService } from 'src/app/core/services/overlay-spinner.service';
import { Subscription, firstValueFrom } from 'rxjs';
import { BcLargeListAlertModalComponent } from '../bc-large-list-alert-modal/bc-large-list-alert-modal.component';

@Component({
  selector: 'app-online-list-upload-review',
  templateUrl: './online-list-upload-review.component.html',
  styleUrls: ['./online-list-upload-review.component.scss']
})
export class OnlineListUploadReviewComponent implements OnInit {
  routeSubscription = new Subscription();

  tableMapFirstCols = [
    {
      value: 'make',
      tableView: 'make'
    },
    {
      value: 'model',
      tableView: 'model'
    }
  ];

  tableMap: TableHeaderMap[] = [
    {
      value: 'variant',
      tableView: 'variant'
    },
    {
      value: 'trim',
      tableView: 'trim'
    },
    {
      value: 'manufactureYear',
      tableView: 'model Year'
    },
    {
      value: 'firstReg',
      tableView: 'first Reg'
    },
    {
      value: 'cocFromSeller',
      tableView: 'coc From Seller'
    },
    {
      value: 'fuelType',
      tableView: 'fuel Type'
    },
    {
      value: 'gearbox',
      tableView: 'gearbox'
    },
    {
      value: 'bodyType',
      tableView: 'chassis'
    },
    {
      value: 'enginePower',
      tableView: 'engine Power'
    },
    {
      value: 'engineVolume',
      tableView: 'engine volume'
    },
    {
      value: 'vatStatus',
      tableView: 'vat Status'
    },
    {
      value: 'damages',
      tableView: 'damages'
    }
  ];

  displayedColumns = ['selectAll', 'regNo', 'vin', ...this.tableMapFirstCols.map(t => t.value), ...this.tableMap.map(t => t.value), 'co2NEDC', 'co2WLTP', 'color', 'doors', 'seats', 'drivingWheels', 'mileage', 'edit'];

  colors = this.carFlowSyncService.carColors;
  drivingWheels = this.carFlowSyncService.carDrivingWheels;

  dataSource = new MatTableDataSource<any>(this.carFlowSyncService.carsAfterIdentifyDTO.map(c => ({ ...c, isSelected: false })));

  carsUploaded = false;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatCheckbox) selectAllCheckbox!: MatCheckbox;

  constructor(private carFlowSyncService: CarFlowSyncService,
    private dialog: MatDialog,
    private snackbar: SnackbarService,
    private spinnerHandlerService: SpinnerHandlerService,) { }

  ngOnInit(): void {
    this.routeSubscription = this.carFlowSyncService.isRouteChangeEvent$.subscribe(
      async (value) => {
        this.carFlowSyncService.setCurrentTab(value);
      }
    );

    this.dataSource.filterPredicate = (data: any, check: string) => {
      if (check === 'true') {
        return data.identifyStatus === 'Unidentified';
      } else {
        return data.identifyStatus.length > 0;
      }
    }

    if (this.carFlowSyncService.carsAfterIdentifyDTO[0].carMainInfoId) {
      this.displayedColumns.splice(0, 1);
      this.displayedColumns.splice(-1);
      this.carsUploaded = true;
    }
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  ngOnDestroy() {
    this.routeSubscription.unsubscribe();
  }

  async continue() {
    if (this.dataSource.data.some(c => c.identifyStatus === 'Unidentified')) {
      this.snackbar.negativeSentiment("Not all cars have been identified!");
      return;
    }

    if (!this.carFlowSyncService.carOnlineListUpload) {
      this.snackbar.negativeSentiment("Settings not completed!");
      return;
    }

    if (this.dataSource.data.length > 50) {
      const dialogRef = this.dialog.open(
        BcLargeListAlertModalComponent, {
        width: '750px',
        maxWidth: '95vw',
        autoFocus: false,
      });

      let response = await firstValueFrom(dialogRef.afterClosed());

      if (response && response == 1) {
        this.carFlowSyncService.changeTab('prices');
      } else if (response && response == 2) {
        this.carFlowSyncService.carsAfterIdentifyDTO.forEach(c => c.businessCase = []);

        this.carFlowSyncService.changeTab('prices');
      }
    } else {
      this.carFlowSyncService.changeTab('prices');
    }
  }

  selectAll(event: MatCheckboxChange) {
    const startIndex = this.paginator!.pageIndex * this.paginator!.pageSize;
    const endIndex = startIndex + this.paginator!.pageSize;

    this.dataSource.data.slice(startIndex, endIndex).forEach(d => d.isSelected = event.checked);
  }

  editCar(car: CarOnlineListUploadResponse) {
    if (this.spinnerHandlerService.showProgressBar.value) return;

    if (car.identifyStatus === 'Unidentified' || car.identifyStatus === 'Invalid data') {
      this.openModifyUnidentifiedCarsModal(car);
    } else {
      this.openModifyPartiallyIdentifiedCarModal(car);
    }
  }

  editMultipleCars() {
    let cars = this.dataSource.data.filter(c => c.isSelected);

    if (cars.length === 0) {
      this.snackbar.negativeSentiment('No cars selected!');
      return;
    }

    if (cars.some(c => c.make != cars[0].make
      || c.model != cars[0].model
      || c.gearbox != cars[0].gearbox
      || c.fuelType != cars[0].fuelType
      || c.enginePower != cars[0].enginePower)
    ) {
      this.snackbar.negativeSentiment('Different cars selected! Please select same cars!');
      return;
    }

    if (cars.some(c => c.identifyStatus != cars[0].identifyStatus)) {
      this.snackbar.negativeSentiment('Different levels of identification selected! Please select cars that are equally identified!');
      return;
    }

    if (cars[0].identifyStatus === 'Unidentified' || cars[0].identifyStatus === 'Invalid data') {
      this.openModifyUnidentifiedCarsModal(cars[0], true);
    } else {
      this.openModifyPartiallyIdentifiedCarModal(cars[0], true);
    }
  }

  openModifyUnidentifiedCarsModal(car?: CarOnlineListUploadResponse, multipleEdit: boolean = false) {
    const dialogRef = this.dialog.open(
      ModifyUnidentifiedCarsModalComponent, {
      width: '1200px',
      maxWidth: '95vw',
      autoFocus: false,
      data: {
        car: car,
        makes: this.carFlowSyncService.carMakes
      }
    });

    dialogRef.afterClosed().subscribe(resp => {
      if (multipleEdit && resp) {
        this.updateMultipleCars(resp);
      } else if (resp) {
        this.updateSingleCar(resp, car!.id)
      }

      this.carFlowSyncService.carsAfterIdentifyDTO = this.dataSource.data;
    })
  }

  openModifyPartiallyIdentifiedCarModal(car: CarOnlineListUploadResponse, multipleEdit: boolean = false) {
    const dialogRef = this.dialog.open(
      ModifyPartiallyIdentifiedCarModalComponent, {
      width: '900px',
      autoFocus: false,
      data: {
        car: car
      }
    });

    dialogRef.afterClosed().subscribe(resp => {
      if (multipleEdit && resp) {
        this.updateMultipleCars(resp);
      } else if (resp) {
        this.updateSingleCar(resp, car.id);
      }

      this.carFlowSyncService.carsAfterIdentifyDTO = this.dataSource.data;
    })
  }

  updateMultipleCars(car: CarIdentifiedModalComponentCar) {
    this.dataSource.data.filter(c => c.isSelected).forEach(c => {
      c.alternatives = car.alternatives;
      c.make = car.make!;
      c.model = car.model!;
      c.variant = car.variant!;
      c.trim = car.trim!;
      c.fuelType = car.fuelType!;
      c.bodyType = car.bodyType;
      c.gearbox = car.gearbox!;
      c.identifyStatus = 'Identified';
      c.engineVolume = car.engineVolume ? car.engineVolume : (c.engineVolume ? c.engineVolume : 0);
      c.enginePower = car.enginePower ? car.enginePower : c.enginePower;
      c.co2NEDC = car.co2NEDC ? car.co2NEDC : c.co2NEDC;
      c.co2WLTP = car.co2WLTP ? car.co2WLTP : c.co2WLTP;
      c.drivingWheels = car.drivingWheels ? car.drivingWheels : c.drivingWheels;
      c.doors = car.doors ? car.doors : c.doors;
      c.seats = car.seats ? car.seats : c.seats;
      c.idAdac = car.idAdac;
    });

    this.selectAllCheckbox.checked = false;
  }

  updateSingleCar(car: CarIdentifiedModalComponentCar, carId: number) {
    let updateCar = this.dataSource.data.find(c => c.id === carId)!;

    updateCar.alternatives = car.alternatives;
    updateCar.make = car.make!;
    updateCar.model = car.model!;
    updateCar.variant = car.variant!;
    updateCar.trim = car.trim!;
    updateCar.fuelType = car.fuelType!;
    updateCar.gearbox = car.gearbox!;
    updateCar.identifyStatus = 'Identified';
    updateCar.engineVolume = car.engineVolume ? car.engineVolume : (updateCar.engineVolume ? updateCar.engineVolume : 0);
    updateCar.enginePower = car.enginePower ? car.enginePower : (updateCar.enginePower ? updateCar.enginePower : 0);
    updateCar.co2NEDC = car.co2NEDC ? car.co2NEDC : (updateCar.co2NEDC ? updateCar.co2NEDC : 0);
    updateCar.co2WLTP = car.co2WLTP ? car.co2WLTP : (updateCar.co2WLTP ? updateCar.co2WLTP : 0);
    updateCar.drivingWheels = car.drivingWheels ? car.drivingWheels : updateCar.drivingWheels;
    updateCar.doors = car.doors ? car.doors : updateCar.doors;
    updateCar.seats = car.seats ? car.seats : updateCar.seats;
    updateCar.bodyType = car.bodyType ? car.bodyType : updateCar.bodyType;
    updateCar.idAdac = car.idAdac;
  }

  filterCars(check: boolean) {
    this.dataSource.filter = check.toString();

    this.selectAllCheckbox.checked = false;
  }

  removeCars() {
    if (this.dataSource.data.filter(c => c.isSelected).length === 0) {
      this.snackbar.negativeSentiment('No cars selected');

      return;
    }

    this.carFlowSyncService.carsAfterIdentifyDTO = this.dataSource.data.filter(c => !c.isSelected);

    this.dataSource.data = this.carFlowSyncService.carsAfterIdentifyDTO;

    this.selectAllCheckbox.checked = false;
  }

  changePageEvent() {
    this.dataSource.data.forEach(d => d.isSelected = false);
    this.selectAllCheckbox.checked = false;
  }
}
