import moment from "moment";

export interface CarDetails {
  adStatus: boolean,
  carStatus: string,
  inactiveFrom: string,
  available: {
    from: string,
    now: boolean,
    to: string
  },
  source: string,
  adCheck: boolean,
  priceUpdate: boolean,
  make: string,
  model: string,
  variant: string,
  trim: string,
  manufactureYear: number,
  bodyType: string,
  co2NEDC: number,
  co2WLTP: number,
  drivingWheels: string,
  enginePower: number,
  engineVolume: number,
  fuelType: string,
  gearbox: string,
  buyingPrice: number,
  sellingPrice: number,
  minSellingPrice: number,
  damages: number,
  b2bPrice: number,
  firstReg: string,
  mileage: number,
  color: string,
  seats: number,
  doors: number,
  vin: string,
  regNo: string,
  seller: string,
  adLink: string,
  otherInfo: string,
  subHeader: string,
  location: string,
  locationName: string,
  carGroup: string,
  carGroupJourney: CarGroupJourney[],
  carTechInfoId: string,
  carMainInfoId: string,
  profilePhoto: string,
  createdAt: Date,
  created_at: number,
  createdAtString: string,
  inactiveFromString: string,
  isSelected: boolean,
  idAdac: number,
  sourceName: string,
  kamName: string,
  sellerName: string,
  documents: CarDocuments[]
  vatStatus: boolean,
  businessCase: CarBusinessCase[],
  businessCaseFlag: boolean,
  cocFromSeller: boolean,
  transportMatrix: {
    countryFrom: string,
    countryTo: string,
    priceIncludingTransport: number,
    transportCost: number,
  }[]
}

export class CreateManualCarBody {
  adCheck: boolean;
  priceUpdate: boolean;
  make: string;
  model: string;
  manufactureYear: number;
  variant: string;
  trim: string;
  fuelType: string;
  gearbox: string;
  engineVolume: number;
  enginePower: number;
  bodyType: string;
  drivingWheels: string;
  co2NEDC: number;
  co2WLTP: number;
  buyingPrice: number;
  minSellingPrice: number;
  damages: number;
  firstReg: string;
  mileage: number;
  color: string;
  seats: number;
  doors: number;
  location: string;
  vin: string;
  regNo: string;
  seller: string;
  adLink: string;
  otherInfo: string;
  vatStatus: boolean;
  idAdac: number;
  availableFrom: string;
  cocFromSeller: boolean;
  subHeader: string;
  journeys: {
    dateFrom: string,
    dateTo: string,
    groupId: string,
    sellingPrice: number
  }[]

  constructor(baseData: any, technicalDataForm: any, pricesForm: any, registrationForm: any, identificationForm: any, seller: string, journeys: {
    dateFrom: string,
    dateTo: string,
    groupId: string,
    sellingPrice: number
  }[]) {
    this.adCheck = false;
    this.priceUpdate = false;
    this.make = baseData.make;
    this.model = baseData.model;
    this.manufactureYear = parseInt(registrationForm.manufactureYear);
    this.variant = baseData.variant;
    this.trim = baseData.trim ? baseData.trim : "";
    this.fuelType = baseData.fuelType;
    this.gearbox = baseData.gearbox;
    this.engineVolume = technicalDataForm.engineVolume ? technicalDataForm.engineVolume : 0;
    this.enginePower = technicalDataForm.enginePower ? technicalDataForm.enginePower : 0;
    this.bodyType = technicalDataForm.bodyType ? technicalDataForm.bodyType : "";
    this.drivingWheels = technicalDataForm.drivingWheels ? technicalDataForm.drivingWheels : "";
    this.co2NEDC = technicalDataForm.co2NEDC ? technicalDataForm.co2NEDC : 0;
    this.co2WLTP = technicalDataForm.co2WLTP ? technicalDataForm.co2WLTP : 0;
    this.buyingPrice = pricesForm.buyingPrice;
    this.minSellingPrice = pricesForm.minSellingPrice;
    this.damages = pricesForm.damages ? pricesForm.damages : 0;
    this.firstReg = registrationForm.firstReg ? (typeof (registrationForm.firstReg) === 'string' ? registrationForm.firstReg : moment(registrationForm.firstReg).format('yyyy-MM-DD')) : "";
    this.mileage = registrationForm.mileage;
    this.color = registrationForm.color ? registrationForm.color : "";
    this.seats = registrationForm.seats ? registrationForm.seats : 0;
    this.doors = registrationForm.doors ? registrationForm.doors : 0;
    this.location = identificationForm.location;
    this.vin = identificationForm.vin ? identificationForm.vin : "";
    this.regNo = identificationForm.regNo ? identificationForm.regNo : "";
    this.seller = seller;
    this.adLink = identificationForm.adLink ? identificationForm.adLink : "";
    this.otherInfo = identificationForm.otherInfo ? identificationForm.otherInfo : "";
    this.subHeader = identificationForm.subHeader ? identificationForm.subHeader : "";
    this.availableFrom = moment(identificationForm.availableFrom).format('yyyy-MM-DD');
    this.vatStatus = pricesForm.vatStatus;
    this.idAdac = baseData.idAdac;
    this.journeys = journeys;
    this.cocFromSeller = identificationForm.cocFromSeller;
  }
}

export class EditManualCarBody {
  adCheck: boolean;
  priceUpdate: boolean;
  manufactureYear: number;
  engineVolume: number;
  enginePower: number;
  bodyType: string;
  drivingWheels: string;
  co2NEDC: number;
  co2WLTP: number;
  buyingPrice: number;
  minSellingPrice: number;
  damages: number;
  firstReg: string;
  mileage: number;
  color: string;
  seats: number;
  doors: number;
  location: string;
  vin: string;
  regNo: string;
  adLink: string;
  otherInfo: string;
  subHeader: string;
  vatStatus: boolean;
  availableFrom: string;
  reset: boolean;
  model: string;
  fuelType: string;
  gearbox: string;
  variant: string;
  trim: string;
  cocFromSeller: boolean;
  idAdac: number;
  sellerId: string;

  constructor(baseDataForm: any, technicalDataForm: any, pricesForm: any, registrationForm: any, identificationForm: any, reset: boolean, sellerId: string) {
    this.adCheck = false;
    this.priceUpdate = false;
    this.manufactureYear = parseInt(registrationForm.manufactureYear);
    this.engineVolume = technicalDataForm.engineVolume ? technicalDataForm.engineVolume : 0;
    this.enginePower = technicalDataForm.enginePower ? technicalDataForm.enginePower : 0;
    this.bodyType = technicalDataForm.bodyType ? technicalDataForm.bodyType : "";
    this.drivingWheels = technicalDataForm.drivingWheels ? technicalDataForm.drivingWheels : "";
    this.co2NEDC = technicalDataForm.co2NEDC ? technicalDataForm.co2NEDC : 0;
    this.co2WLTP = technicalDataForm.co2WLTP ? technicalDataForm.co2WLTP : 0;
    this.buyingPrice = pricesForm.buyingPrice;
    this.minSellingPrice = pricesForm.minSellingPrice;
    this.damages = pricesForm.damages ? pricesForm.damages : 0;
    this.firstReg = registrationForm.firstReg ? (typeof (registrationForm.firstReg) === 'string' ? registrationForm.firstReg : moment(registrationForm.firstReg).format('yyyy-MM-DD')) : "";
    this.mileage = registrationForm.mileage;
    this.color = registrationForm.color ? registrationForm.color : "";
    this.seats = registrationForm.seats ? registrationForm.seats : 0;
    this.doors = registrationForm.doors ? registrationForm.doors : 0;
    this.location = identificationForm.location;
    this.vin = identificationForm.vin ? identificationForm.vin : "";
    this.regNo = identificationForm.regNo ? identificationForm.regNo : "";
    this.adLink = identificationForm.adLink ? identificationForm.adLink : "";
    this.otherInfo = identificationForm.otherInfo ? identificationForm.otherInfo : "";
    this.subHeader = identificationForm.subHeader ? identificationForm.subHeader : "";
    this.cocFromSeller = identificationForm.cocFromSeller === true ? true : false;
    this.availableFrom = moment(identificationForm.availableFrom).format('yyyy-MM-DD');
    this.vatStatus = pricesForm.vatStatus;
    this.reset = reset;
    this.model = baseDataForm.model;
    this.fuelType = baseDataForm.fuelType;
    this.gearbox = baseDataForm.gearbox;
    this.variant = baseDataForm.variant;
    this.trim = baseDataForm.trim ? baseDataForm.trim : '';
    this.idAdac = baseDataForm.idAdac;
    this.sellerId = sellerId;
  }
}

export interface CreateManualCarResponse {
  car: string,
  carMainInfoId: string,
  businessCase: string
}

export interface IdentifyCarResponse {
  make?: string,
  model?: string,
  fuelType?: string,
  gearbox?: string,
  variant?: string,
  trim?: string,
  bodyType?: string,
  co2NEDC?: number,
  co2WLTP?: number,
  doors?: number,
  drivingWheels?: string,
  enginePower: number,
  engineVolume: number,
  seats: number,
  fullCarName: string,
  idAdac: number,
  standardEquipment: [any],
  optionalEquipment: [any],
  packEquipment: [any]
}

export class IdentifyCarRequestClass {
  make: string;
  model: string;
  fuelType: string;
  gearbox: string;
  variant: string;
  trim?: string;

  public constructor(baseData: any) {
    this.make = baseData.make;
    this.model = baseData.model;
    this.fuelType = baseData.fuelType;
    this.gearbox = baseData.gearbox;
    this.variant = baseData.variant;
    this.trim = baseData.trim ? baseData.trim : undefined;
  }
}

export interface CarSpecificationResponse {
  id: number,
  name: string
}

export interface CarGroupJourney {
  journeyId: string,
  dateFrom: string,
  dateTo: string,
  groupId: string,
  active: boolean,
  sellingPrice: number
}

export interface CarAdSettings {
  seller: string,
  carStatus: string,
  inactiveFrom: string,
  source: string,
}

export interface CarStatuses {
  id: string,
  status: string
}

export class CreateCarJourneyBody {
  dateFrom: string;
  dateTo: string;
  groupId: string;
  car: string;
  active: boolean;
  sellingPrice: number;

  constructor(
    dateFrom: string,
    timeFrom: string,
    dateTo: string,
    timeTo: string,
    group: string,
    car: string,
    sellingPrice: number,
  ) {
    this.dateFrom = moment(`${dateFrom} ${timeFrom}:00:00`).utc().format('yyyy-MM-DD HH:mm:ss');
    this.dateTo = moment(`${dateTo} ${timeTo}:00:00`).utc().format('yyyy-MM-DD HH:mm:ss');
    this.groupId = group;
    this.car = car;
    this.active = true;
    this.sellingPrice = sellingPrice;
  }
}

export interface CarCreatedResponse {
  car: string;
}

export interface UploadCarPhoto {
  carMainInfoId: string,
  uploadedImages: {
    name: string,
    content: string
  }[]
}

export interface ReorderCarPhotos {
  carMainInfoId: string,
  photos: CarPhoto[]
}

export interface CarPhoto {
  _id: string,
  originalPhoto: string,
  name: string,
  addedBy: string
}

export interface CarMasterEquipments {
  standardEquipment: any[],
  optionalEquipment: any[],
  packEquipment: any[]
}

export interface CarC2VEquipments {
  customEquipment: string[]
}

export interface CarBusinesscaseRequestBody {
  countries: string[],
  make: string,
  model: string,
  manufactureYear: number,
  fuelType: string,
  gearbox: string,
  bodyType: string,
  variant: string,
  mileage: number,
  enginePower: number,
  firstReg: string,
  accessories: string[],
  carMainInfoId: string
}

export interface CarBusinessCaseResponse {
  carMainInfoId: string,
  businessCase: CarBusinessCase[]
}

export interface CarBusinessCase {
  spotPrice: number,
  spotPriceExTax: number,
  spotCountry: string,
  spotLevel: string,
  spotCurrency: string,
  carsOnline: number,
  carsSold30Days: number,
  salesFactor: number,
  error: string
  errorDetails: string
}

export interface CarTaxesRequest {
  method: string
  cars: {
    make: string | null,
    model: string | null,
    fuelType: string | null,
    engineVolume: number | null,
    firstReg: Date | null
    co2WLTP: number | null,
    co2NEDC: number | null,
    enginePower: number | null,
    drivingWheel: string | null,
    gearbox: string | null,
    buyingPrice: number | null
  }[]
}


export interface CarTaxesResponse {
  make: string | null,
  model: string | null,
  fuelType: string | null,
  engineVolume: number | null,
  taxes: [{
    country: string,
    tax: number,
  }]
}

export interface CarSearch {
  nrOfCars: number,
  fuelType: any,
  carGroup: any,
  source: any,
  location: any,
  gearbox: any,
  bodyType: any,
  VAT: any
  cars: CarDetails[]
}

export interface CarSearchResp {
  nrOfCars: number,
  cars: CarDetails[],
  pageReset?: boolean
}

export interface CarGroupDetails {
  name: string,
  id: string,
  createdAt: Date,
  nrOfCars: number
}

export interface CarGroupDetailsBaseData {
  make?: string,
  model?: string,
  variant?: string,
  trim?: string,
  fuelType?: string,
  gearbox?: string
}

export class CarGroupDetailsBaseDataClass implements CarGroupDetailsBaseData {
  public constructor(init?: Partial<CarGroupDetailsBaseDataClass>) {
    Object.assign(this, init);
  }
}

export interface CarGroupDetailsTechnicalData {
  enginePower?: number,
  engineVolume?: number,
  co2NEDC?: number,
  co2WLTP?: number,
  bodyType?: string,
  drivingWheels?: string
}

export class CarGroupDetailsTechnicalDataClass implements CarGroupDetailsTechnicalData {
  public constructor(init?: Partial<CarGroupDetailsTechnicalDataClass>) {
    Object.assign(this, init);
  }
}

export interface CarGroupDetailsPricesData {
  buyingPrice?: number,
  sellingPrice?: number,
  damages?: number,
  b2bPrice?: number,
  minSellingPrice?: number
}

export class CarGroupDetailsPricesDataClass implements CarGroupDetailsPricesData {
  public constructor(init?: Partial<CarGroupDetailsPricesDataClass>) {
    Object.assign(this, init);
  }
}

export interface CarGroupDetailsRegistrationData {
  firstReg?: Date,
  mileage?: number,
  color?: string,
  seats?: number,
  doors?: number
}

export class CarGroupDetailsRegistrationDataClass implements CarGroupDetailsRegistrationData {
  public constructor(init?: Partial<CarGroupDetailsRegistrationDataClass>) {
    Object.assign(this, init);
  }
}

export interface CarGroupDetailsIdentificationData {
  vin?: string,
  regNo?: string,
  adLink?: string,
  otherInfo?: string,
  location?: string,
}

export class CarGroupDetailsIdentificationDataClass implements CarGroupDetailsIdentificationData {
  public constructor(init?: Partial<CarGroupDetailsIdentificationDataClass>) {
    Object.assign(this, init);
  }
}

export interface CarsVINCheck {
  vins: {
    carMainInfoId: string,
    locationId: string,
    vin: string
  }[]
}

export interface VINCheckResponse {
  batchId: string,
  count: number,
  completed: boolean,
  completedCount?: number
}

export interface VINCheckDetails {
  carMainInfoId: string,
  equipment: any
}

export interface VINCheckDetailsEquipment {
  name: string,
  soaCode: number,
  originalDescription: string,
  standard: boolean,
  packageFlag: boolean,
  priceBrutto: number,
  currency: string,
  isOpen?: boolean,
  equipmentItems:
  {
    name: string,
    mapping: string,
    originalDescription: string,
    equipmentCategory?: string
  }[]
}

export interface VINCountries {
  iso: string,
  country: string
}


export interface CarsFilterBody {
  page: number,
  itemsPerPage: number,
  carGroup?: string[],
  carStatus?: string[],
  makeModel?: {
    make: string,
    model: string
  }[],
  gearbox?: string[],
  fuelType?: string[],
  mileage?: {
    from: number,
    to: number
  },
  manufactureYear?: {
    from: number,
    to: number
  },
  enginePower?: {
    from: number,
    to: number
  },
  journey?:{
    from: string,
    to: string
  },
  bodyType?: string[],
  category?: string[],
  seller?: string,
  vin?: string,
  vatStatus?: boolean,
  location?: string[],
}


export interface CarDocuments {
  id: string,
  name: string,
  doc: string,
  visible: boolean
}


export class CarBatchOnlineUpload {
  idAdac: number;
  vin: string;
  regNo: string;
  otherInfo: string;
  subHeader: string;
  doors: number | null;
  seats: number | null;
  color: string;
  firstReg: string;
  co2NEDC: number | null;
  co2WLTP: number | null;
  drivingWheels: string;
  engineVolume: number | null;
  trim: string;
  vatStatus: boolean;
  make: string;
  model: string;
  manufactureYear: number;
  variant: string;
  fuelType: string;
  gearbox: string;
  enginePower: number;
  bodyType: string;
  mileage: number;
  damages: number;
  minSellingPrice: number;
  buyingPrice: number;
  cocFromSeller: boolean;
  carPricing: number[];
  businessCase: any[];
  c2vEquipment: string[];

  constructor(car: any, prices: number[]) {
    this.idAdac = car.idAdac ? car.idAdac : '';
    this.vin = car.vin ? car.vin.toString() : '';
    this.regNo = car.regNo ? car.regNo.toString() : '';
    this.otherInfo = car.notes ? car.notes : '';
    this.subHeader = car.subHeader ? car.subHeader : '';
    this.doors = car.doors ? car.doors : null;
    this.seats = car.seats ? car.seats : null;
    this.color = car.color ? car.color : '';
    this.firstReg = car.firstReg ? car.firstReg.toString() : '';
    this.cocFromSeller = car.cocFromSeller ? car.cocFromSeller : false;
    this.co2NEDC = car.co2NEDC ? car.co2NEDC : 0;
    this.co2WLTP = car.co2WLTP ? car.co2WLTP : 0;
    this.drivingWheels = car.drivingWheels ? car.drivingWheels : '';
    this.engineVolume = car.engineVolume ? car.engineVolume : 0;
    this.trim = car.trim ? car.trim : '';
    this.vatStatus = car.vatStatus ? car.vatStatus : false;
    this.make = car.make ? car.make : '';
    this.model = car.model ? car.model : '';
    this.manufactureYear = car.manufactureYear ? car.manufactureYear : 0;
    this.variant = car.variant ? car.variant : '';
    this.fuelType = car.fuelType ? car.fuelType : '';
    this.gearbox = car.gearbox ? car.gearbox : '';
    this.enginePower = car.enginePower ? car.enginePower : 0;
    this.bodyType = car.bodyType ? car.bodyType : '';
    this.mileage = car.mileage ? car.mileage : 0;
    this.damages = car.damages ? car.damages : 0;
    this.c2vEquipment = car.c2vEquipment;
    this.minSellingPrice = car.minSellingPrice ? car.minSellingPrice : 0;
    this.buyingPrice = car.buyingPrice ? car.buyingPrice : 0;
    this.carPricing = prices;
    this.businessCase = typeof (car.businessCase) === 'string' ? [] : car.businessCase;
  }
}

export class CarDocumentUploadBody {
  carMainInfoId: string;
  doc: {
    name: string
    visible: boolean,
    data: string
  };

  constructor(carMainInfoId: string, doc: {
    name: string
    visible: boolean,
    data: string
  }) {
    this.carMainInfoId = carMainInfoId;
    this.doc = doc;
  }
}

