import moment from "moment"
import { Buyer, BuyerStore, BuyerStoreContact } from "./buyer.model"
import { CarDetails, CarDocuments } from "./car.model"
import { KAMBuyers, User } from "./user.model"
import { UtilsService } from "../services/utils.service"

export interface Order {
  buyer: {
    id: string,
    buyerPaid: boolean,
    buyerPaidAt: Date,
    name: string,
    vat: string,
    address: string,
    postalCode: string,
    email: string,
    country: {
      locationId: string,
      location: string,
      iso: string,
    },
    client: {
      id: string,
      fullName: string,
      phoneNumber: string,
      email: string,
    },
    store: {
      id: string,
      name: string,
      address: string,
      city: string,
      postalCode: string,
      country: {
        locationId: string,
        location: string,
        iso: string,
      },
      contactDetails: {
        id: string,
        firstName: string,
        lastName: string,
        email: string,
        phone: string,
        documentEmail: boolean,
        carDeliveryEmail: boolean,
        accEmail: boolean
      }
    }
  },
  createdAt: Date,
  createdBy: {
    userId: string,
    username: string
  }
  dhlActualValue: number,
  dhlValue: number,
  estimatedPaymentDate: Date,
  kamBuyer: {
    id: string,
    username: string
  },
  modifiedAt: Date,
  notes: string,
  oneInvoicePerCar: boolean,
  orderDate: Date,
  orderId: string,
  removedCars: OrderCar[],
  requestedDeliveryDate: Date,
  documentToCompanyAddress: boolean,
  documents: IDocument[],
  status: string
  subStatus?: string
  sellers: OrderSeller[],
  separateInvoiceForTransport: boolean,
  teammates: OrderTeammate[],
  totalActualProfit: number,
  totalInitialProfit: number,
  totalInvoiced: number,
  transportDetails: {
    actualFee: number,
    carsNotInTransport: number,
    fee: number,
    profit: number,
    transportByC2C: boolean
    transports: OrderTransport[]

  },
  vinBatch: {
    status: string,
    batchId: string
  },
  profitAnalysis: {
    totalInitialProfit: number,
    totalActualProfit: number,
    totalInvoiced: number
  },
}

export interface OrderCar {
  photoEditing: {
    photoEdit: boolean,
    photoEditValue: number,
    photoEditActualValue: number
  },
  carMainInfoId: string,
  carConfig: CarDetails & { actualDamages: number },
  carAvailability: string,
  carPricing: {
    buyingPrice: number,
    minSellingPrice: number,
    sellingPrice: number,
    shopPrice: number,
    invoiceSellingPrice: number,
    transportationFee: number,
    transportationActualFee: number,
    currency: string,
    actualBuyingPrice: number,
    actualBuyingPriceEuro: number,
    initialProfit: number,
    actualProfit: number,
    offerDetails: {
      offerId: string,
      offeredValue: number
    }
  },
  deliveryDetails: {
    city: string,
    address: string,
    postalCode: string,
    contactDetails: {
      email: string,
      phone: string,
      firstName: string,
      lastName: string,
      fullName: string
    }
  },
  pickupDetails: {
    locationId: string,
    location: string,
    iso: string,
    city: string,
    address: string,
    postalCode: string,
    contactDetails: {
      email: string,
      phone: string,
      firstName: string,
      lastName: string,
      fullName: string
    }
  },
  documentsDetails: {
    country: {
      id: string,
      iso: string,
      name: string,
    }
    city: string,
    address: string,
    postalCode: string,
    contactDetails: {
      email: string,
      phone: string,
      firstName: string,
      lastName: string,
      fullName: string
    },
    documents: {
      coc: {
        required: boolean,
        cocValue: number,
        cocFrom: string,
        documents: IDocument[],
        cocBySeller: boolean,
        receivedAt: Date,
        requestedAt: Date,
        sentAt: Date
      },
      registration: {
        documents: IDocument[],
        receivedAt: Date,
        sentAt: Date,
        requestedAt: Date
      },
      invoicing: {
        documents: IDocument[]
      },
      cc: {
        documents: IDocument[],
        receivedAt: Date,
        sentAt: Date
      }
    }
  },
  carReady: boolean,
  carReadyAt: Date,
  carRegisteredC2C: boolean,
  carDeRegisteredC2C: boolean,
  dhlRequested: boolean,
  dhlTrackingNo: string,
  exchangeRate: number,
  invoicingCompany: {
    id: string,
    name: string
  },
  sellerInvoiceVATDeposit: boolean,
  claims: [],
  vatString: string
}

export interface OrderCarDocument {
  id: string,
  name: string,
  doc: string,
  createdAt: number,
  addedBy: {
    userId: string,
    username: string
  }
}

export interface IDocument {
  doc: string,
  id: string,
  name: string,
  visible?: boolean
}

export interface OrderSettings {
  kam: KAMBuyers,
  buyer: Buyer,
  userBuyer: User,
  buyerStore: BuyerStore,
  buyerContact: BuyerStoreContact,
  orderDate: Date
}

export interface OrderOptions {
  requestDeliveryDate: Date,
  estimatedDeliveryDate: Date,
  separateInvoiceTransport: boolean,
  separateInvoiceCar: boolean,
  documentsSameAddress: boolean,
}

export interface ShortInfoOrder {
  buyer: string,
  carsInOrder: number,
  createdBy: string,
  delegatedKam: string,
  invoiceSellingPrice: number,
  orderDate: string,
  orderId: string,
  paymentStatus: boolean,
  profit: number,
  status: string,
}

export interface GetOrdersResponse {
  orders: ShortInfoOrder[],
  nrOfOrders: number
}

export interface AllOrderTasks {
  sellerTasks: OrderTask[],
  carTasks: OrderTask[]
}

export interface OrderUpdatedProfitResponse {
  totalInitialProfit: number,
  totalActualProfit: number,
  dhlValue: number,
  dhlActualValue: number,
  cars: {
    carMainInfoId: string,
    actualProfit: number,
    initialProfit: number,
    transportationFee: number,
    transportationActualFee: number,
    photoEditValue: number,
    photoEditActualValue: number,
    cocValue: number,
    cocActualValue: number,
  }[]
}

export interface OrderTask {
  description: string,
  sellerId: string,
  orderId: string,
  sellerName?: string,
  carDescription?: string,
  carMainInfoId?: string
}

export interface OrderSeller {
  details: {
    id: string,
    name: string,
    address: string,
    phoneNumber: string,
    email: string,
    vat: string,
    type: string,
    status: boolean,
    city: string,
    postalCode: string,
    vatStatus: boolean,
    vatValidationDate: Date,
    country: {
      id: string,
      name: string,
      iso: string,
    },
    currency: string,
    sellerPayment: boolean,
    sellerPaymentAt: string,
    bank: string,
    OCR: string,
    invoiceEmailSent: any
  },
  kamSeller: {
    id: string,
    username: string,
    phoneNumber: string,
    email: string,
  },
  cars: OrderCar[]
}

export class UpdateCarDeliveryDetailsClass {
  orderId: string;
  sellerId: string;
  carMainInfoId: string;
  city: string;
  address: string;
  postalCode: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;

  constructor(orderId: string, sellerId: string, carMainInfoId: string, details: any) {
    this.orderId = orderId
    this.sellerId = sellerId
    this.carMainInfoId = carMainInfoId
    this.city = details.city
    this.address = details.address
    this.postalCode = details.postalCode
    this.firstName = details.contactDetails.firstName
    this.lastName = details.contactDetails.lastName
    this.email = details.contactDetails.email
    this.phone = details.contactDetails.phone
  }
}

export class UpdateCarPickupDetailsClass {
  orderId: string;
  sellerId: string;
  carMainInfoId: string;
  city: string;
  address: string;
  postalCode: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  carReadyAt: string;

  constructor(orderId: string, sellerId: string, carMainInfoId: string, details: any) {
    this.orderId = orderId;
    this.sellerId = sellerId;
    this.carMainInfoId = carMainInfoId;
    this.city = details.city ? details.city : '';
    this.address = details.address ? details.address : '';
    this.postalCode = details.postalCode ? details.postalCode : '';
    this.firstName = details.contactDetails.firstName ? details.contactDetails.firstName : '';
    this.lastName = details.contactDetails.lastName ? details.contactDetails.lastName : '';
    this.email = details.contactDetails.email ? details.contactDetails.email : '';
    this.phone = details.contactDetails.phone ? details.contactDetails.phone : '';
    this.carReadyAt = details.carReady ? moment(details.carReadyAt).format('yyyy-MM-DD') : ''
  }
}

export interface AddCarsToOrderBody {
  orderId: string,
  clientId: string,
  carMainInfoIds: string[],
}

export interface RemoveCarFromOrderBody {
  orderId: string,
  sellerId: string,
  carMainInfoId: string,
}

export interface UpdateCarAvailabilityAndReadyForPickupBody {
  orderId: string,
  cars: {
    carMainInfoId: string,
    carReady: boolean,
    availability: boolean
  }[]
}

export interface OrderTeammate {
  credit: number,
  mainActor: boolean,
  mandatory: boolean,
  userId: string,
  username: string,
  profit?: number
}

export interface SaveProfitSplitBody {
  orderId: string,
  users: {
    userId: string,
    credit: number
  }[]
}

export interface CurrenciesToEuro {
  rate: number,
  currency: string
}

export interface SaveSellerCurrencyBody {
  orderId: string,
  sellerId: string,
  currency: string
}

export interface UpdateCarPriceSettingsBody {
  orderId: string,
  carMainInfoId: string,
  currency: string,
  cocValue: number,
  exchangeRate: number,
  invoiceSellingPrice: number,
  actualBuyingPrice: number,
  actualBuyingPriceEuro: number,
  photoEditActualValue: number
  actualDamages: number,
  notes: string,
  cocBySeller: boolean,
  cocFrom: string
}

export class UpdateDocumentsDetailsBody {
  orderId: string;
  carMainInfoId: string;
  sellerId: string;
  carRegisteredC2C: string;
  carDeRegisteredC2C: string;
  dhlRequested: string;
  dhlTrackingNo: string;
  countryId: string;
  registration: {
    receivedAt: string,
    sentAt: string,
    requestedAt: string
  };
  cc: {
    receivedAt: string,
    sentAt: string,
  };
  coc: {
    receivedAt: string,
    sentAt: string,
    requestedAt: string
  };

  constructor(orderId: string, sellerId: string, carId: string, documentDetails: any) {
    this.orderId = orderId;
    this.carMainInfoId = carId;
    this.sellerId = sellerId;
    this.carRegisteredC2C = documentDetails.carRegisteredC2C;
    this.carDeRegisteredC2C = documentDetails.carDeRegisteredC2C;
    this.dhlRequested = documentDetails.dhlRequested;
    this.dhlTrackingNo = documentDetails.dhlTrackingNo;
    this.countryId = documentDetails.country ? documentDetails.country : '';
    this.registration = {
      receivedAt: documentDetails.registration.receivedAt,
      sentAt: documentDetails.registration.sentAt,
      requestedAt: documentDetails.registration.requestedAt,
    };
    this.cc = {
      receivedAt: documentDetails.cc.receivedAt,
      sentAt: documentDetails.cc.sentAt,
    };
    this.coc = {
      receivedAt: documentDetails.coc.receivedAt,
      sentAt: documentDetails.coc.sentAt,
      requestedAt: documentDetails.coc.requestedAt,
    };
  }
}

export class UpdateDocumentsAddressBody {
  orderId: string;
  sellerId: string;
  carMainInfoId: string;
  city: string;
  address: string;
  postalCode: string;
  email: string;
  phone: string;
  firstName: string;
  lastName: string;

  constructor(orderId: string, sellerId: string, carId: string, documentDetails: any) {
    this.orderId = orderId;
    this.carMainInfoId = carId;
    this.sellerId = sellerId;
    this.city = documentDetails.city;
    this.address = documentDetails.address;
    this.postalCode = documentDetails.postalCode;
    this.email = documentDetails.email;
    this.phone = documentDetails.phone;
    this.firstName = documentDetails.firstName;
    this.lastName = documentDetails.lastName;
  }
}

export interface ConfirmSellerPaymentBody {
  orderId: string,
  sellerId: string,
  sellerPayment: boolean,
  sellerPaymentAt: Date,
  bank: string,
  OCR: string
}

export interface UploadCarDocumentBody {
  orderId: string,
  carMainInfoId: string,
  sellerId: string,
  doc: {
    name: string,
    data: string,
    type: string,
  }
}

export interface ApproveCarBody {
  orderId: string,
  sellerId: string,
  carMainInfoId: string
}

//Create order classes
export class CreateOrderBodyClass {
  kamId: string
  orderDate: Date
  batchId: string
  requestedDeliveryDate: Date
  estimatedPaymentDate: Date
  oneInvoicePerCar: boolean
  separateInvoiceForTransport: boolean
  transportDetails: { transportByC2C: boolean }
  notes: string
  buyer: {
    buyerId: string;
    clientId: string;
    storeId: string;
    kamId: string;
    contactDetails:
    {
      email: string;
      phone: string;
      firstName: string;
      lastName: string;
      id: string;
      source: string
    }
  }
  sellers: {
    sellerId: string;
    cars: CreateOrderCarClass[]
  }[]

  constructor(orderSettings: Required<OrderSettings>, orderOptions: Required<OrderOptions>, transportC2C: boolean, batchId: string, notes: string) {
    this.kamId = '';
    this.orderDate = orderSettings.orderDate;
    this.requestedDeliveryDate = orderOptions.requestDeliveryDate;
    this.estimatedPaymentDate = orderOptions.estimatedDeliveryDate;
    this.oneInvoicePerCar = orderOptions.separateInvoiceCar;
    this.separateInvoiceForTransport = orderOptions.separateInvoiceTransport;
    this.transportDetails = {
      transportByC2C: transportC2C
    };
    this.notes = notes;
    this.batchId = batchId;
    this.buyer = {
      buyerId: '',
      clientId: orderSettings.userBuyer.clientId!,
      storeId: orderSettings.buyerStore.id!,
      kamId: orderSettings.kam.kamId,
      contactDetails: {
        phone: orderSettings.buyerContact.phone!,
        email: orderSettings.buyerContact.email!,
        firstName: orderSettings.buyerContact.firstName!,
        lastName: orderSettings.buyerContact.lastName!,
        id: orderSettings.buyerContact.email!,
        source: 'Mongo'
      }
    };
    this.sellers = [];
  }
}

export class CreateOrderCarClass {
  photoEdit: boolean;
  // vinDecoded: boolean;
  // fromOffer: boolean;
  carMainInfoId: string;
  // invoiceSellingPrice: number;
  // offeredPrice: number;
  // finalDamages: number;
  // availability: boolean;
  deliveryDetails: {
    city: string,
    address: string,
    postalCode: string,
    contactDetails: {
      email: string,
      phone: string,
      firstName: string,
      lastName: string,
    }
  };
  pickupDetails: {
    // country: string,
    // countryName: string,
    city: string,
    address: string,
    postalCode: string,
    contactDetails: {
      email: string,
      phone: string,
      firstName: string,
      lastName: string,
    }
  };
  documentsDetails: {
    city: string,
    address: string,
    postalCode: string,
    contactDetails: {
      firstName: string,
      lastName: string,
      email: string,
      phone: string,
    },
    // cocDocuments: {
    //   required: boolean
    // }
  }

  constructor(car: OrderCar) {
    this.photoEdit = false;
    // this.vinDecoded = car.vinDecoded ? car.vinDecoded : false;
    // this.fromOffer = car.fromOffer ? car.fromOffer : false;
    this.carMainInfoId = car.carMainInfoId!;
    // this.invoiceSellingPrice = car.invoiceSellingPrice!;
    // this.offeredPrice = car.offeredPrice ? car.offeredPrice : 0;
    // this.finalDamages = car.finalDamages!;
    // this.availability = car.availability ? car.availability : false;
    this.documentsDetails = {
      city: car.documentsDetails.city,
      address: car.documentsDetails.address,
      postalCode: car.documentsDetails.postalCode,
      contactDetails: {
        firstName: car.documentsDetails.contactDetails!.firstName,
        lastName: car.documentsDetails.contactDetails!.lastName,
        email: car.documentsDetails.contactDetails!.email,
        phone: car.documentsDetails.contactDetails!.phone,
      },
      // cocDocuments: {
      // required: car.documentsDetails.cocDocuments.required
      // }
    }
    this.pickupDetails = {
      // country: car.pickupDetails.country,
      // countryName: car.pickupDetails.countryName,
      city: car.pickupDetails.city,
      address: car.pickupDetails.address,
      postalCode: car.pickupDetails.postalCode,
      contactDetails: {
        email: car.pickupDetails.contactDetails!.email,
        phone: car.pickupDetails.contactDetails!.phone,
        firstName: car.pickupDetails.contactDetails!.firstName,
        lastName: car.pickupDetails.contactDetails!.lastName,
      }
    }
    this.deliveryDetails = {
      city: car.deliveryDetails.city,
      address: car.deliveryDetails.address,
      postalCode: car.deliveryDetails.postalCode,
      contactDetails: {
        email: car.deliveryDetails.contactDetails!.email,
        phone: car.deliveryDetails.contactDetails!.phone,
        firstName: car.deliveryDetails.contactDetails!.firstName,
        lastName: car.deliveryDetails.contactDetails!.lastName,
      }
    }
  }
}

export class SendOrderBodyClass extends CreateOrderBodyClass {
  orderId: string;

  constructor(orderSettings: Required<OrderSettings>, orderOptions: Required<OrderOptions>, transportC2C: boolean, batchId: string, notes: string, orderId: string) {
    super(orderSettings, orderOptions, transportC2C, batchId, notes)
    this.orderId = orderId;
  }
}

export interface CreateTransportBody {
  orderId: string,
  name: string,
}

export interface OrderTransport {
  actualDeliveryDate: string,
  estimatedDeliveryDate: string,
  pickupDate: string,
  status: string,
  transportId: string,
  load: string,
  name: string,
  truckReg: string,
  cars: (OrderCar & {
    transportationActualFee: number, transportationFee: number
  })[],
  documents: {
    CMR: IDocument[],
    CMRSentAt: string,
    invoice: IDocument[],
    invoiceSentAt: string
  }
}

export interface OrderTransportDocument {
  doc: string,
  id: string,
  name: string,
  addedBy: {
    userId: string,
    username: string
  }
}

export interface UpdateCarTransportCostBody {
  orderId: string,
  carMainInfoId: string,
  transportId: string,
  transportationActualFee: number,
}

export interface OrderTransportResponse {
  actualFee: number,
  carsNotInTransport: number,
  fee: number,
  profit: number,
  transportByC2C: boolean,
  transports: OrderTransport[]
}

export interface UploadTransportDocumentBody {
  orderId: string,
  transportId: string,
  doc: {
    name: string,
    type: string,
    data: string
  }
}

export interface UpdateTransportDocumentsSendDate {
  orderId: string,
  id: string,
  invoiceSent?: string,
  CMRSent?: string,
  estimatedDeliveryDate?: string
}

export interface UpdateOrderOptionsBody {
  orderId: string,
  dhlValue: number,
  requestedDeliveryDate: string,
  estimatedPaymentDate: string,
  transportByC2C: boolean,
  separateInvoiceForTransport: boolean,
  oneInvoicePerCar: boolean,
  notes: string,
}

export interface UpdateTransportStatusBody {
  orderId: string,
  id: string,
  status: string
}

export interface SearchOrdersBody {
  page: number,
  itemsPerPage: number,
  orderId?: string,
  createdBy?: string,
  delegatedKam?: string,
  buyer?: string,
  status?: string,
  paymentStatus?: string,
  dateFrom?: string,
  dateTo?: string,
}

export interface SearchOrdersCarsBody {
  vin?: string | null,
  regNo?: string | null,
  make?: string | null,
  model?: string | null,
  variant?: string | null,
  orderNo?: string | null,
  invoiceNo?: string | null,
  orderFrom?: string | null,
  orderTo?: string | null,
  orderStatus?: string | null,
  subStatus?: string | null,
  carStatus?: string | null,
  carLocation?: string | null,
  carDeliveryLocation?: string | null,
  transportStatus?: string | null,
  carReady?: boolean | string | null,
  pickupDateFrom?: string | null,
  pickupDateTo?: string | null,
  transportByC2C?: boolean | string | null,
  kamBuyer?: string | null,
  kamSeller?: string | null,
}

export interface CarOrderDetailsResp {
  carConfig: CarDetails & {
    c2cEquipment: {
      optionalEquipment: any[],
      packEquipment: any[],
      standardEquipment: any[]
    },
    c2vEquipment: any[]
  },
  photos: {
    originalPhoto: string,
    _id: string
  }[]
}

export interface UpdateCarsInvoicingCompanyResp {
  orderId: string,
  newOrders: string[]
}

export class UpdateTransportDetailsBody {
  orderId: string;
  transportId: string;
  load: string;
  name: string;
  truckReg: string;
  status: string;
  pickupDate: string;
  estimatedDeliveryDate: string;
  actualDeliveryDate: string;
  invoiceSentAt: string;
  CMRSentAt: string;
  utilsService: any;

  constructor(orderId: string, transportId: string, transportForm: any, utilsService: UtilsService) {
    this.orderId = orderId;
    this.transportId = transportId;
    this.load = transportForm.load;
    this.name = transportForm.companyName;
    this.truckReg = transportForm.truckReg;
    this.status = transportForm.status;
    this.pickupDate = transportForm.pickupDate ? utilsService.formatDateValue(transportForm.pickupDate) : '';
    this.estimatedDeliveryDate = transportForm.estimatedDeliveryDate ? utilsService.formatDateValue(transportForm.estimatedDeliveryDate) : '';
    this.actualDeliveryDate = transportForm.actualDeliveryDate ? utilsService.formatDateValue(transportForm.actualDeliveryDate) : '';
    this.invoiceSentAt = transportForm.invoiceSentAt ? utilsService.formatDateValue(transportForm.invoiceSentAt) : '';
    this.CMRSentAt = transportForm.CMRSentAt ? utilsService.formatDateValue(transportForm.CMRSentAt) : '';
  }
}

export interface UpdateCarAvailability {
  orderId: string;
  sellerId: string;
  carMainInfoId: string;
  carAvailability: string;
}

export interface UploadOrderDocumentBody {
  orderId: string,
  name: string,
  visible: boolean,
  content: string,
}

export interface RemoveOrderDocumentBody {
  orderId: string,
  id: string,
  filter: {
    carFilter?: {
      sellerId: string,
      carMainInfoId: string,
      docType: string
    },
    transportFilter?: {
      transportId: string,
      docType: string,
    }
  } | {}
}


export interface IOrderCarFlatView {
  vin: string,
  regNo: string,
  make: string,
  model: string,
  variant: string,
  orderStatus: string,
  orderDate: string,
  orderId: string,
  carStatus: string,
  transportStatus: string,
  readyForPickup: boolean,
  pickupDate: string,
  estDeliveryDate: string,
  carLocation: string,
  carDeliveryLocation: string,
  transportByC2C: boolean,
  documents: string,
  CMR: string,
  COC: string,
  sellerPaid: string,
  buyerPaid: string,
  invoicingPrice: number,
  invoicingDate: string,
  purchasePrice: number,
  sellingPrice: number,
  buyerName: string,
  buyerCountry: string,
  sellerName: string,
  sellerCountry: string,
  kamBuyer: string,
  kamSeller: string,
}
