import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { BehaviorSubject, EMPTY, Subject, Subscription, delay, expand, firstValueFrom, of, repeat, retry, takeLast, takeUntil } from 'rxjs';
import { CarBatchOnlineUpload } from 'src/app/core/models/car.model';
import { CarFlowSyncService } from 'src/app/core/services/car-flow-sync.service';
import { CarService } from 'src/app/core/services/car.service';
import { SpinnerHandlerService } from 'src/app/core/services/overlay-spinner.service';
import { SnackbarService } from 'src/app/core/services/snackbar.service';
import { DropdownOption } from 'src/app/shared/app-dropdown/app-dropdown.component';
import { TableHeaderMap } from 'src/app/shared/app-table/app-table.component';

@Component({
  selector: 'app-online-list-upload-prices',
  templateUrl: './online-list-upload-prices.component.html',
  styleUrls: ['./online-list-upload-prices.component.scss']
})
export class OnlineListUploadPricesComponent implements OnInit {
  routeSubscription = new Subscription();

  loading = new BehaviorSubject<boolean>(true);

  carsUploaded = false;

  tableMap: TableHeaderMap[] = [
    {
      value: 'regNo',
      tableView: 'REG NUMBER'
    },
    {
      value: 'vin',
      tableView: 'vin'
    },
    {
      value: 'make',
      tableView: 'make'
    },
    {
      value: 'model',
      tableView: 'model'
    },
    {
      value: 'variant',
      tableView: 'variant'
    },
    {
      value: 'trim',
      tableView: 'trim'
    },
    {
      value: 'manufactureYear',
      tableView: 'model Year'
    },
    {
      value: 'firstReg',
      tableView: 'first Reg'
    },
    {
      value: 'fuelType',
      tableView: 'fuel Type'
    },
    {
      value: 'gearbox',
      tableView: 'gearbox'
    },
    {
      value: 'bodyType',
      tableView: 'chassis'
    },
    {
      value: 'enginePower',
      tableView: 'engine Power'
    },
    {
      value: 'mileage',
      tableView: 'mileage'
    },
    {
      value: 'spotPrice',
      tableView: 'Retail ex. VAT'
    }
  ];

  journeyPricesCols: TableHeaderMap[] = this.carFlowSyncService.carOnlineListUpload!.journeys.map((j, index) => ({ value: `journey${index}`, tableView: `Journey ${index + 1}` }));

  displayedColumns = ['selectAll', 'priceError', ...this.tableMap.map(t => t.value), 'damages', 'minSellingPrice', 'buyingPrice', ...this.journeyPricesCols.map(c => c.value)];

  businessCaseIsoCountries: DropdownOption[] = [];
  businessCaseIsoCountriesControl = new FormControl('');

  dataSource = new MatTableDataSource<any>();

  stop$ = new Subject();

  bcAsyncMessage = '';

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(private carFlowSyncService: CarFlowSyncService,
    private spinner: SpinnerHandlerService,
    private snackbar: SnackbarService,
    private carService: CarService) { }

  async ngOnInit() {
    this.routeSubscription = this.carFlowSyncService.isRouteChangeEvent$.subscribe(
      async (value) => {
        this.carFlowSyncService.setCurrentTab(value);
      }
    );

    if (!this.carFlowSyncService.carsAfterIdentifyDTO[0].businessCase) {
      let body = this.carFlowSyncService.carsAfterIdentifyDTO.map(c => ({
        make: c.make,
        model: c.model,
        manufactureYear: c.manufactureYear,
        fuelType: c.fuelType,
        gearbox: c.gearbox,
        bodyType: c.bodyType,
        variant: c.variant,
        mileage: c.mileage,
        enginePower: c.enginePower,
        firstReg: c.firstReg ? c.firstReg : '',
      }));

      this.carService.getOnlineListCarsBCAsyncPost(body).subscribe({
        next: bcAsyncResp => {
          let ignoredIndexes: number[] = bcAsyncResp.ignoredIndexes ? bcAsyncResp.ignoredIndexes : [];

          if (ignoredIndexes.length === this.carFlowSyncService.carsAfterIdentifyDTO.length) {
            this.carFlowSyncService.carsAfterIdentifyDTO.forEach(c => {
              c.businessCase = [];
              c.isSelected = false;
            })

            this.loadPageInfo();

            return;
          }

          this.carService.getOnlineListCarsBCAsync(bcAsyncResp.analysisId)
            .pipe(
              delay(1000),
              expand((bcResult: any) => {
                if (bcResult.code === 'SPOT_ASYNC_IN_PROCESS' || bcResult.code === 'ENTITY_NOT_FOUND') {
                  this.bcAsyncMessage = bcResult.code === 'ENTITY_NOT_FOUND' ? 'Business case process started' : bcResult.message;

                  return this.carService.getOnlineListCarsBCAsync(bcAsyncResp.analysisId).pipe(delay(1000));
                }

                let ignoredIndexesCounter = 0;

                let incrIgnoredIndexesCounter = function () { ignoredIndexesCounter++; return [] };

                this.carFlowSyncService.carsAfterIdentifyDTO = this.carFlowSyncService.carsAfterIdentifyDTO.map((c, index) => ({
                  ...c,
                  businessCase: ignoredIndexes.includes(index) ? incrIgnoredIndexesCounter() : bcResult[index - ignoredIndexesCounter].businessCase,
                  isSelected: false
                }));

                this.loadPageInfo();

                return EMPTY;
              }),
              takeLast(1),
            ).subscribe();
        },
        error: err => {
          if(err.status === 400 && err.error.path){
            this.snackbar.negativeSentiment(`${err.error.error} for car ${parseInt(err.error.path.split('[')[1].split(']')[0]) + 1}`);

            this.carFlowSyncService.carsAfterIdentifyDTO.forEach(c => {
              c.businessCase = [];
              c.isSelected = false;
            })

            this.loadPageInfo();

            return;
          }
          console.log(err)
        }
      })
    } else {
      this.loadPageInfo();
    }
  }

  loadPageInfo() {
    this.dataSource.data = this.carFlowSyncService.carsAfterIdentifyDTO;

    this.filterBCISOCodes(this.dataSource.data);

    if (this.businessCaseIsoCountries.length > 0) {
      this.changeBC(this.businessCaseIsoCountries[0].value);

      this.businessCaseIsoCountriesControl.setValue(this.businessCaseIsoCountries[0].value);
    }

    if (this.carFlowSyncService.carsAfterIdentifyDTO[0].carMainInfoId) {
      this.displayedColumns.splice(0, 1);
      this.carsUploaded = true
    };

    this.loading.next(false);
  }

  ngOnDestroy() {
    this.routeSubscription.unsubscribe();
  }

  uploadCars() {
    if (this.spinner.showProgressBar.value) return;

    if (this.dataSource.data.some(c => this.checkPrice(c))) {
      this.snackbar.negativeSentiment('One or more cars have selling prices lower than minimum selling price');
      return;
    }

    let body = this.carFlowSyncService.carOnlineListUpload!;

    let journeys = Object.keys(this.dataSource.data[0]).filter(k => k.match('journey'));

    body!.cars = this.dataSource.data.map(c => new CarBatchOnlineUpload(c, journeys.map(j => parseInt(c[j]))));

    this.spinner.showProgressBar.next(true);

    this.carService.uploadOnlineListCars(body).subscribe({
      next: resp => {
        this.spinner.showProgressBar.next(false);

        this.snackbar.positiveSentiment('Cars uploaded');

        this.carFlowSyncService.carsAfterIdentifyDTO.forEach((c, index) => {
          c.carMainInfoId = resp[index];
        });

        this.carFlowSyncService.changeTab('photos');
      },
      error: err => {
        this.spinner.showProgressBar.next(false);

        this.snackbar.negativeSentiment(err.error);
      }
    })
  }

  filterBCISOCodes(data: any) {
    this.businessCaseIsoCountries = data.reduce((arr: DropdownOption[], car: any) => {
      if (typeof (car.businessCase) === 'string') return arr;

      car.businessCase.forEach((bc: any) => {
        if (bc.spotCountry && !arr.find(c => c.value === bc.spotCountry)) {
          arr.push({ viewValue: bc.spotCountry, value: bc.spotCountry });
        }
      })
      return arr;
    }, []);
  }

  checkPrice(car: any) {
    return this.journeyPricesCols.some(c => car.minSellingPrice > car[c.value])
  }

  changeBC(iso: string) {
    this.dataSource.data = this.dataSource.data.map(car => ({
      ...car,
      spotPrice: typeof (car.businessCase) === 'string' ? car.spotPrice : (car.businessCase.find((bc: any) => bc.spotCountry === iso) ? car.businessCase.find((bc: any) => bc.spotCountry === iso).spotPrice : 'No spot price for this country')
    }))
  }

  removeCars() {
    if (this.dataSource.data.filter(c => c.isSelected).length === 0) {
      this.snackbar.negativeSentiment('No cars selected');

      return;
    }

    this.carFlowSyncService.carsAfterIdentifyDTO = this.dataSource.data.filter(c => !c.isSelected);

    this.dataSource.data = this.carFlowSyncService.carsAfterIdentifyDTO;
  }
}
